<template>
  <section class="container vld-parent" id="newTemplateMainSection">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#114C8E"
      id="newTemplateLoader"
    ></loading>
    <b-row id="newTemplateTitleRow">
      <h2 class="text-primary" id="newTemplateTitle">New Order Template</h2>
    </b-row>
    <b-row id="newTemplateProductDetailsRow">
      <b-card style="width: 100%" id="newTemplateProductDetailsCard">
        <b-row id="newTemplateProductDetailsCardRow">
          <b-col id="newTemplateProductDetailsCardCol">
            <DeliveryDetails
              :ValidationModel="$v"
              id="newTemplateProductDeliveryDetails"
            />
            <ProductDetails id="newTemplateProductProductDetails" />
            <SpecialInstructions id="newTemplateProductSpecialInstructions" />
          </b-col>
        </b-row>
        <b-row id="newTemplateProductBottomButtonsRow">
          <b-col class="text-right" id="newTemplateProductBottomButtonsCol">
            <b-button
              variant="primary"
              @click="saveTemplate()"
              id="newTemplateProductSaveTemplateButton"
              >Save Template</b-button
            >
          </b-col>
        </b-row>
      </b-card>
    </b-row>
    <slideout-panel></slideout-panel>
  </section>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { mapState, mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import { handleRequestError } from "@/shared/utils/response-error-handler";
import _ from "lodash";

import DeliveryDetails from "@/components/new-order-template/DeliveryDetails";
import ProductDetails from "@/components/new-order-template/ProductDetails";
import SpecialInstructions from "@/components/new-order-template/SpecialInstructions";

export default {
  name: "NewTemplate.vue",

  components: {
    Loading,
    DeliveryDetails,
    ProductDetails,
    SpecialInstructions,
  },

  data() {
    return {
      isLoading: false,
      errorText: "",
    };
  },

  async created() {
    try {
      //handles loading from child
      this.$root.$on("isEditLoading", (val) => {
        this.isLoading = val;
      });

      //clear any existing tender order data
      this.$store.dispatch("newOrderTemplate/clearOrderTemplateData");
      this.submitSuccessful = false;
      this.showOrderConfirmationModal = false;
      this.tenderId = "";

      //Load All BillTos
      await this.$store.dispatch(
        "newOrderTemplate/getAllBillTos",
        this.$store.state.user.jwtToken
      );

      //Load All Consignees
      if (this.$store.state.newOrderTemplate.billTos.length > 0) {
        await this.$store.dispatch("newOrderTemplate/getConsignees", {
          jwtToken: this.$store.state.user.jwtToken,
          billTo: this.$store.state.newOrderTemplate.billTos[0].id,
        });
      }
    } catch (error) {
      const errorMessage = handleRequestError(error);
      this.$snotify.error(errorMessage, "Error");
    }
  },

  methods: {
    async saveTemplate() {
      this.isLoading = true;
      this.$v.$touch();
      if (this.isValidTemplate()) {
        const billTo =
          this.$store.state.newOrderTemplate.selectedProducts[0].deliverySite
            .billTo;

        const selectedBillTo = _.find(this.billTos, { id: billTo });

        const objBillTo = {
          id: selectedBillTo.id,
          name: selectedBillTo.name,
        };

        const details = this.selectedProducts.map((stop) => ({
          deliverySite: {
            ...stop.deliverySite,
            address1: stop.deliverySite.address,
          },
          shipper: stop.shipper,
          supplier: stop.supplier,
          accountOf: stop.accountOf,
          amount: stop.amount,
          product: stop.product,
          notes: "",
          components: [],
          amountUnitOfMeasure: stop.amountUnitOfMeasure, //"Gallon"
        }));

        const objOrderTemplate = {
          templateName: this.templateName,
          receiver: "TMWCLT",
          sender: "TRPFCS",
          division: this.getDivisionForTemplate,
          billTo: objBillTo,
          po: this.poNumber.trim() ? this.poNumber : null,
          notes: this.notes,
          details: details,
          additionalReferenceNumber: "",
          isMultiUser: this.isMultiUser,
          isAssignToAllUsers: this.isAssignToAllUsers,
          selectedUsers: this.getFilteredSelectedUsersUIds,
        };

        const requestData = {
          orderTemplate: objOrderTemplate,
          jwtToken: this.$store.state.user.jwtToken,
          toast: this.$snotify,
        };

        this.$store
          .dispatch("newOrderTemplate/saveOrderTemplate", requestData)
          .then(() => {
            this.$router.push({ name: "order-templates" });
          })
          .catch((error) => {
            let errorMessage = handleRequestError(error);
            this.$snotify.error(errorMessage, "Error");
          });
      }
      this.isLoading = false;
    },

    isValidTemplate() {
      if (this.$v.$invalid) {
        if (
          this.$v.selectedProducts.$error &&
          this.$v.selectedProducts.minLength
        ) {
          this.$snotify.error("You must add at least one product! ", "Error");
        }
        return false;
      }
      return true;
    },
  },

  computed: {
    ...mapState("newOrderTemplate", [
      "templateName",
      "accountOfs",
      "billTos",
      "shippers",
      "suppliers",
      "commodities",
      "consignees",
      "selectedConsignee",
      "selectedDeliveryDate",
      "selectedDeliveryTime",
      "selectedFromCustomTime",
      "selectedToCustomTime",
      "poNumber",
      "notes",
      "selectedProducts",
      "selectedAmountUnitOfMeasure",
      "isMultiUser",
      "isAssignToAllUsers",
    ]),

    ...mapGetters("newOrderTemplate", [
      "getFilteredSelectedUsersUIds",
      "getDivisionForTemplate",
    ]),
  },

  validations() {
    return {
      templateName: {
        required,
      },
      selectedProducts: {
        required,
        minLength: minLength(1),
      },
    };
  },
};
</script>

<style scoped>
</style>
